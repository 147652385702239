import React from 'react';
import { RouterProvider } from 'react-router-dom';

import './index.css';

import { router } from './router';

/* const test2dUrl = 'https://entre-reality-public.s3.ap-northeast-2.amazonaws.com/result_gs2d_iters_10000.plyn'; */
/* const test3dUrl = 'https://entre-reality-public.s3.ap-northeast-2.amazonaws.com/result_gs3d_iters_10000.ply'; */

const App: React.FC = () => {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
