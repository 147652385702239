import { useEffect } from 'react';
import { createBrowserRouter, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

const Fallback: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ pathname: '/view', search: params.toString() }, { replace: true });
  }, [navigate, params]);

  return null;
};

export const router = createBrowserRouter([
  {
    path: 'view',
    lazy: async () => {
      const { ViewPage } = await import('../../pages/view');
      return { Component: ViewPage };
    },
  },
  {
    path: '*',
    element: <Fallback />,
  },
]);
